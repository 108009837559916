import consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailV2
    from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailV2'
import consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailCenter
    from '@/lib/data-service/haolv-default/consumer_t-od-trains-order-refund_getTrainsReturnOrderDetailCenter'
import consumer_insurance_getEInsuranceOrderUrl
    from '@/lib/data-service/haolv-default/consumer_insurance_getEInsuranceOrderUrl'
import consumer_air_ticket_ApprovalMethod from '@/lib/data-service/haolv-default/consumer_air_ticket_ApprovalMethod'
import consumer_trains_order_getSchedule from '@/lib/data-service/haolv-default/consumer_trains_order_getSchedule'
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import TrainTimetable from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-timetable/1.0.0/index.vue'
import TrainOrderRulePopup from '@/page/admin/travel-book/train/component/orderRulePopup/1.0.0/index.vue'
export default {
    data() {
        return {
            approvalMethod: null,
            checkList: [],
            refundNo: '',
            orderInfo: {
                receiveOption: [
                    {
                        headCounts: null,
                        receiveFee: null,
                        refundCharge: null,
                        robTciketFee: null,
                        serviceFee: null,
                    },
                ],
                trainInfo: {
                    trainNo: null
                }
            },
            routeName: '',
            // orderInfo: {
            //     refundNo:'2656265265265',
            //     orderNo:'18185721357',
            //     gmtCreate:'2020-07-07  08:40:50',
            //     paymentChanelText:'授信支付',
            //     scheduledWayText:'出差创建',
            //     refundStatusText:'退票成功',
            //     receiveAmount:'400',
            //     refundAmount:'200',
            //     receiveOption:{
            //         serviceFee:'4',
            //         refundCharge:'96',
            //     },
            //     evectionNo:'CA58587458',
            //     buyerContactName:'郭鑫',
            //     buyerContactPhone:'18788888888',
            //     trainInfo:{
            //         trainNo:'G696',
            //         startDate:'2021-09-10',
            //         whatDay:'星期五',
            //         seatName:'软卧',
            //         fromStationName:'广州南站',
            //         startTime:'06:25',
            //         spanTime:'6小时48分钟',
            //         toStationName:'上海虹桥',
            //         arriveTime:'09:15',
            //         seatPrice:'50',
            //     },
            //     passengers:[
            //         {
            //             passengerCardTypeText: "身份证",
            //             passengerName: "李明丽",
            //             passengerTypeText: "成人",
            //             unSensitivePassengerCardNumber: "522425********0147",
            //             unSensitivePassengerPhone: "181****1357"
            //         },
            //         {
            //             passengerCardTypeText: "护照",
            //             passengerName: "张三",
            //             passengerTypeText: "成人",
            //             unSensitivePassengerCardNumber: "E*****000",
            //             unSensitivePassengerPhone: "181****1357"
            //         }
            //     ]
            // },
            scheduleList: [],

            orderRuleDialogVisible: false,
        }
    },
    components: {
        TrainOrderRulePopup,
        TrainTimetable
    },
    created() {

    },
    mounted() {
        this.routeName = this.$route.name;
    },
    async activated() {
        this.refundNo = this.$route.query.refundNo;
        if (this.$route.name == 'admin-order-center-train-refund-details') {
            await this.getRefundOrderDetail();
        } else {
            await this.getRefundOrderDetailV2();
        }
        await this.getSchedule();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterIntervalTime(val) {
            if (val) {
                // 转换为式分秒
                let h = parseInt(val / 60 / 60 % 24);
                h = h < 10 ? '0' + h : h;
                let m = parseInt(val / 60 % 60);
                m = m < 10 ? '0' + m : m;
                let s = parseInt(val % 60);
                s = s < 10 ? '0' + s : s;
                let text = `${m}分${s}秒`;
                if (h !== '00') {
                    text = h + '时' + text
                }
                return text
            } else {
                return '00分00秒'
            }
        },
        filterApproveStatus(val) {
            // 0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效
            if (val === 0) {
                return '未开始'
            } else if (val === 1) {
                return '待我审'
            } else if (val === 2) {
                return '审核通过'
            } else if (val === 3) {
                return '驳回'
            } else if (val === 4) {
                return '已失效'
            } else {
                return ''
            }
        },
    },
    methods: {
        checkApplyNo() {
            if (this.approvalMethod == 1) {
                // 我的申请
                this.$router.push({
                    name: 'admin-my-apply-manage-apply-info',
                    query: {
                        applyId: this.orderInfo.applyId,
                        type: 'info',
                        approveType: this.orderInfo.approveType,
                        lastMenu: 'adminMyApply'
                    }
                })
            } else {
                // 待我审批
                this.$router.push({
                    name: 'admin-my-apply-manage-noApprove-detail',
                    query: {
                        applyId: this.orderInfo.applyId,
                        type: 'audit',
                        approveType: this.orderInfo.approveType,
                    }
                })
            }
        },
        clickInsurance(val) {
            if (val.insuranceOrderId) {
                let data = {
                    insuranceOrderId: val.insuranceOrderId,
                };
                consumer_insurance_getEInsuranceOrderUrl(data).then(res => {
                    const __this = this;
                    const eleLink = document.createElement('a');
                    eleLink.href = res.datas.epolicyUrl;
                    eleLink.target = '_blank';
                    document.body.appendChild(eleLink);
                    eleLink.click();
                    document.body.removeChild(eleLink);
                })
            } else {
                this.$message.error('正在生成保单信息，请稍后查询...');
            }

        },
        clickSpan() {
            if (this.$route.meta.data_for_back_layout && this.$route.meta.data_for_back_layout.layoutType === 'orderCenterType') {
                this.$router.push({
                    name: 'admin-order-center-train-refund-list'
                })
            } else {
                this.$router.push({
                    name: 'admin-my-order-train-refund-list'
                })
            }
        },
        async getRefundOrderDetail() {
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let [err, res] = await awaitWrap(consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailCenter({refundNo: this.refundNo}));

            if (err) {
                loading.close()
                return
            }
            loading.close()
            this.orderInfo = res.datas
            this.orderInfo.receiveOption = [res.datas.receiveOption];
        },
        async getRefundOrderDetailV2() {
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });


            let [err, res] = await awaitWrap(consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailV2({refundNo: this.refundNo}))

            if (err) {
                loading.close()
                return
            }
            loading.close()
            this.orderInfo = res.datas
            this.orderInfo.receiveOption = [res.datas.receiveOption];

            let params = {
                fromStationCode: this.orderInfo.fromStationCode,
                toStationCode: this.orderInfo.toStationCode,
                trainCode: this.orderInfo.trainInfo.trainNo,
                trainDate: this.$moment(this.orderInfo.trainInfo.startDate).format("YYYY-MM-DD"),
            };
            this.$refs.aTrainTimetable.init(params);
        },
        async getSchedule() {
            if (this.orderInfo.applyNo) {
                consumer_air_ticket_ApprovalMethod({applyNo: this.orderInfo.applyNo}).then(res => {
                    this.approvalMethod = res.datas.approvalMethod;
                });
            }

            if (!this.orderInfo.applyNo) {
                return
            }
            let res = await consumer_trains_order_getSchedule({applyNo: this.orderInfo.applyNo})
            this.scheduleList = res.datas
        },
        checkEvection() {
            this.$router.push({
                name: 'admin-my-apply-manage-apply-info',
                query: {
                    applyId: this.orderInfo.applyId,
                    type: 'info',
                    approveType: 1,
                    lastMenu: 'adminMyApply'
                }
            })
        },
        setStatus(val) {
            // 车票状态 0：待预定，1：待出票，2：出票中，3：出票完成，4：出票失败, 5：退票已申请, 6：退票处理中,
            // 7：退票完成， 8：退票失败，9：改签已申请，10：改签中， 11：改签完成，12：改签失败，
            // 13：改签已取消， 14：已改签， 15：待支付， 16：预定中， 17：占座失败
            let result = '';
            if ([3, 7, 11].indexOf(val) >= 0) {
                result = 'success'//绿色
            } else if ([0, 13].indexOf(val) >= 0) {
                result = 'cancel'//灰色
            }else if ([4, 8, 12, 17].indexOf(val) >= 0) {
                result = 'fail'//红色
            } else {
                result = 'pending'//黄色
            }
            return result
        },
        back() {
            this.$router.back();
        },
        showRulePopup() {
            this.orderRuleDialogVisible = true;
        },
    }
}
